import { Route, Routes as ReactRoutes } from "react-router-dom";
import LoginPage from "../Auth/LoginPage";
import SignupPage from "../Auth/SignupPage";
import PrivateRoute from "./private";
import ProjectPage from "../ProjectPage";

export default function Routes() {
  return (
    <ReactRoutes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/signup" element={<SignupPage />} />
      <Route
        path="/"
        element={
          <PrivateRoute>
            <ProjectPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/debug"
        element={
          <PrivateRoute>
            <ProjectPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/:path/debug"
        element={
          <PrivateRoute>
            <ProjectPage />
          </PrivateRoute>
        }
      />
    </ReactRoutes>
  );
}
